import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from './search-icon.svg';
import { CheckBox } from '../CheckBox/CheckBox';
import Input from '../Input/Input';
import { classNames } from '../utils';
import './InputDropDown.scss';

export const InputDropDownSearch = ({
  autoComplete = 'off',
  options,
  specificOptions,
  name,
  error,
  value,
  onChange,
  label = '',
  placeholder,
  subText,
  className,
  topLabel,
  initialStatus,
  isRequired,
  isMulti,
  isDisabled
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const dropdownRef = useRef(null);

  const inputClasses = classNames({
    'input-wrapper-dropdown': true,
    active: isMenuOpen || value?.length,
    disabled: isDisabled,
    'multi-select': isMulti,
    error: !!error,
    hit: !error && !!initialStatus,
    [className]: true
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMenuOpen(false);

        if (!value) {
          setSearchText('');
        } else {
          setSearchText(getValue() || '');
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef, value, searchText]);

  useEffect(() => {
    if (value && value !== searchText) {
      setSearchText(getValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  const getValue = () => {
    if (isMulti) {
      const multiValue = options?.filter((option) => value?.includes(option.key));
      return multiValue.map((val) => val.value).join('/');
    }
    return options?.find((option) => option?.key === value)?.value;
  };

  const inputChange = (data) => {
    if (isMulti) {
      const valueSet = value?.includes(data) ? value.filter((val) => val !== data) : [...value, data];
      return onChange(name, valueSet);
    }
    onChange(name, data);
    setIsMenuOpen(false);
  };

  const onSearchFocus = (e) => {
    if (options && !isDisabled && !(isMenuOpen && e.target?.name === name)) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const onSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div ref={dropdownRef} onClick={onSearchFocus} className={inputClasses}>
      {topLabel && <p className={'top-label'}>{topLabel}</p>}
      <Input
        label={label + (isRequired ? '*' : '')}
        autoComplete={autoComplete}
        name={name}
        value={searchText}
        onChange={(e) => onSearchChange(e)}
        Icon={SearchIcon}
        className={'input-dropdown-input'}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
      {isMenuOpen && (
        <div className={'input-dropdown-menu'}>
          {options
            .filter((dataPoint) => dataPoint.value.toLowerCase().startsWith(searchText.toLowerCase()))
            .map((dataPoint) => (
              <CheckBox
                key={dataPoint.key}
                onChange={() => inputChange(dataPoint.key)}
                isActive={(isMulti && value?.includes(dataPoint.key)) || dataPoint.key === value}
                showCheckBox={isMulti}
                value={dataPoint.value}
              />
            ))}
          {specificOptions &&
            specificOptions.map((dataPoint) => (
              <CheckBox
                key={dataPoint.key}
                onChange={() => inputChange(dataPoint.key)}
                isActive={(isMulti && value?.includes(dataPoint.key)) || dataPoint.key === value}
                showCheckBox={isMulti}
                value={null}
                content={dataPoint.content}
              />
            ))}
        </div>
      )}
      {(error || subText) && <p className={`input-sub-text ${error ? 'input-error' : ''}`}>{error || subText}</p>}
    </div>
  );
};

InputDropDownSearch.propTypes = {
  autoComplete: PropTypes.string,
  options: PropTypes.array,
  specificOptions: PropTypes.array,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  subText: PropTypes.string,
  className: PropTypes.string,
  topLabel: PropTypes.string,
  initialStatus: PropTypes.number,
  isRequired: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool
};
