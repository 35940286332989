import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CUSTOMER_TYPE, DIRECTION, REPRESENTATIVE_POP_UP } from 'components/constants';
import i18n from 'i18n';
import {
  addAmlNoteRequest,
  addNewPaymentProviderRequest,
  addCustomerNoteRequest,
  addRepresentativeRequest,
  applyTariffRequest,
  blockCustomerCardRequest,
  changeCustomerTariffGroupRequest,
  deleteCompanyStructureMemberRequest,
  deleteCustomerDocumentRequest,
  deleteNoteRequest,
  downloadNoteDocumentRequest,
  downloadDocumentByDocumentIdRequest,
  downloadPaymentTransactionDocumentRequest,
  exportBalancesList,
  exportCustomersList,
  getAdminsListRequest,
  getAmlRequest,
  getAmlTemplateRequest,
  getCompanyStructureRequest,
  getCorporateCustomerDetailsRequest,
  getCustomerAmlNotesRequest,
  getCustomerCardsRequest,
  getCustomerDocumentsRequest,
  getCustomerLogsRequest,
  getCustomerNotesRequest,
  getCustomersListRequest,
  getCustomerTransactionsNotesRequest,
  getIndividualCustomerDetailsRequest,
  getLimitListRequest,
  getLinkedRuleEngineProviderRequest,
  getPaymentDocumentsRequest,
  getPaymentProvidersInfo,
  getTariffGroupsListRequest,
  getTariffsGroupRequest,
  linkRuleEngineProviderRequest,
  loadCustomerCsvReportRequest,
  loadCustomerPdfReportRequest,
  removeRepresentativeRequest,
  replaceCustomerCardRequest,
  requestExternalKyc,
  saveAmlRequest,
  sendCompanyStructureRequest,
  unblockCustomerCardRequest,
  unlinkRuleEngineProviderRequest,
  updateCardsPerAccountLimitRequest,
  updateCardsPerCustomerLimitRequest,
  updateCorporateCustomerDetailsRequest,
  updateCustomerDocumentRequest,
  updateCustomerEmailRequest,
  updateCustomerExternalKycReferenceRequest,
  updateCustomerStatusRequest,
  updateDirectorApprovalRequest,
  updateIndividualCustomerDetailsRequest,
  updateKycStatusRequest,
  updateMlroApprovalRequest,
  updateNextAMLCheckDateRequest,
  updateOnboardingStatusRequest,
  updateRepresentativeRequest,
  updateRiskProfileRequest,
  uploadCustomerDocumentRequest,
  activateCustomerRequest,
  blockCustomerRequest,
  administrateCustomerRequest
} from 'services/requestAgent';
import { convertDate, convertToFullDate } from 'services/utils';

class CustomersStore {
  isInitialized = false;
  isLoading = false;
  isLoadingCustomerCsv = false;
  isLoadingBalancesCsv = false;
  isLoadingNotes = false;
  isLoadingLogs = false;
  isTariffsLoaded = false;
  isCompanyStructureLoaded = false;
  isLimitsLoaded = false;
  isShowActiveCustomers = false;
  isCurrentCustomerDetailsReceived = false;
  isCardActionsLoading = false;
  error = null;
  customers = [];
  admins = [];
  currentCustomerDetails = {};
  currentCustomerProviders = null;
  currentCustomerLinkedRuleEngineProvider = null;
  currentCustomerCards = null;
  currentCustomerNotes = null;
  currentCustomerAmlNotes = null;
  currentCustomerTransactionsNotes = null;
  currentCustomerLogs = null;
  currentCustomerMonitoringDocuments = null;
  currentCustomerAml = null;
  currentCustomerDocuments = null;
  firstAccountCreatedAt = null;
  representativePopUpType = null;
  companyStructure = {
    authorizedPerson: null,
    beneficiaries: [],
    directors: []
  };
  limits = [];
  tariffs = [];
  selectedTariffsGroup = null;
  totalElements = null;
  sortColumn = {
    sort_column: 'created_at',
    sort_direction: DIRECTION.DESC
  };
  pagination = {
    size: 20,
    page: 0,
    totalPages: null
  };
  filters = {
    search_text: null,
    onboarding_statuses: [],
    customer_owners: [],
    types: [],
    account_statuses: [],
    risk_profiles: [],
    kyc_statuses: []
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetCustomersStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isLoadingCustomerCsv = false;
    this.isLoadingBalancesCsv = false;
    this.isLoadingNotes = false;
    this.isLoadingLogs = false;
    this.isCompanyStructureLoaded = false;
    this.isTariffsLoaded = false;
    this.isLimitsLoaded = false;
    this.isCardActionsLoading = false;
    this.error = null;
    this.customers = [];
    this.admins = [];
    this.currentCustomerDetails = {};
    this.currentCustomerProviders = null;
    this.currentCustomerLinkedRuleEngineProvider = null;
    this.currentCustomerCards = null;
    this.currentCustomerNotes = null;
    this.currentCustomerAmlNotes = null;
    this.currentCustomerTransactionsNotes = null;
    this.currentCustomerLogs = null;
    this.currentCustomerMonitoringDocuments = null;
    this.currentCustomerAml = null;
    this.currentCustomerDocuments = null;
    this.firstAccountCreatedAt = null;
    this.representativePopUpType = null;
    this.companyStructure = {
      authorizedPerson: null,
      beneficiaries: [],
      directors: []
    };
    this.limits = [];
    this.tariffs = [];
    this.selectedTariffsGroup = null;
    this.totalElements = null;
    this.sortColumn = {
      sort_column: 'created_at',
      sort_direction: DIRECTION.DESC
    };
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
    this.filters = {
      search_text: null,
      from_date: null,
      to_date: null,
      onboarding_statuses: [],
      customer_owners: [],
      types: [],
      account_statuses: [],
      risk_profiles: [],
      kyc_statuses: []
    };
  };

  resetCustomersStorePagination = () => {
    this.totalElements = null;
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsLoadingCustomersCsv = (status) => {
    this.isLoadingCustomerCsv = status;
    this.error = null;
  };

  setIsLoadingBalancesCsv = (status) => {
    this.isLoadingBalancesCsv = status;
    this.error = null;
  };

  setIsLoadingNotes = (status) => {
    this.isLoadingNotes = status;
    this.error = null;
  };

  setIsLoadingLogs = (status) => {
    this.isLoadingNotes = status;
    this.error = null;
  };

  setIsShowActiveCustomers = (status) => {
    this.isShowActiveCustomers = status;
  };

  setIsCardActionsLoading = (status) => {
    this.isCardActionsLoading = status;
  };

  setRepresentativePopUpType = (type) => {
    this.representativePopUpType = type;
  };

  setPageNumber = (page) => {
    this.pagination.page = page;
  };

  setPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.sortColumn.sort_column = sortData.sortBy;
    this.sortColumn.sort_direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    const filters = { ...this.filters };
    const pagination = { ...this.pagination };

    // Mapping URL filter parameters to corresponding properties in the 'filters' object
    const filterParamsMapping = {
      search_text: 'search_text',
      from_date: 'from_date',
      to_date: 'to_date',
      onboarding_statuses: 'onboarding_statuses',
      customer_owners: 'customer_owners',
      types: 'types',
      account_statuses: 'account_statuses',
      risk_profiles: 'risk_profiles',
      kyc_statuses: 'kyc_statuses'
    };

    // Iterating over each URL parameter and assigning its values to 'filters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        // Convert `from_date` and `to_date` if they exist
        if (param === 'from_date' || param === 'to_date') {
          value = convertToFullDate(value);
        }

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    // Assigning pagination parameters from the URL
    pagination.page = parseInt(params.page) || pagination.page;
    pagination.size = parseInt(params.size) || pagination.size;

    // Assigning sorting parameters from the URL
    this.sortColumn.sort_column = params.sort_column || this.sortColumn.sort_column;
    this.sortColumn.sort_direction = params.sort_direction || this.sortColumn.sort_direction;

    // Updating the state of filters and pagination
    this.filters = filters;
    this.pagination = pagination;
  };

  prepareFiltersParams = (type) => {
    const params = { ...this.filters };

    if (type === 'customers') {
      if (this.filters.from_date) {
        params.from_date = convertDate(this.filters.from_date);
      } else {
        delete params.from_date;
      }

      if (this.filters.to_date) {
        params.to_date = convertDate(this.filters.to_date);
      } else {
        delete params.to_date;
      }
    }

    if (type === 'csv') {
      params.balance_on_date = moment(params.balance_on_date || new Date()).format('YYYY-MM-DD');
      params.balance_on_time = (params.balance_on_time || moment(0).utc()).format('HH-mm-ss');
    }

    return params;
  };

  requestInitialData = async () => {
    this.setIsLoading(true);
    try {
      const admins = await getAdminsListRequest();
      const filtersParams = this.prepareFiltersParams('customers');

      const res = await getCustomersListRequest(
        this.sortColumn.sort_column,
        this.pagination.page,
        this.pagination.size,
        this.sortColumn.sort_direction,
        filtersParams.search_text,
        filtersParams,
        this.isShowActiveCustomers
      );

      runInAction(() => {
        this.isInitialized = true;
        this.isTariffsLoaded = true;
        this.isLimitsLoaded = true;
        this.customers = res.content;
        this.admins = admins;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getCustomersList = async () => {
    this.setIsLoading(true);
    try {
      const filtersParams = this.prepareFiltersParams('customers');

      const res = await getCustomersListRequest(
        this.sortColumn.sort_column,
        this.pagination.page,
        this.pagination.size,
        this.sortColumn.sort_direction,
        filtersParams.search_text,
        filtersParams,
        this.isShowActiveCustomers
      );

      runInAction(() => {
        this.customers = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  handleExportCustomers = async () => {
    this.setIsLoadingCustomersCsv(true);

    const toastPromise = toast.promise(exportCustomersList(this.prepareFiltersParams('csv')), {
      pending: i18n.getMessage('customersStore.handleExportCustomers.toastPromise.pending'),
      success: i18n.getMessage('customersStore.handleExportCustomers.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoadingCustomerCsv = false;
      });
    }
  };

  handleExportBalances = async () => {
    this.setIsLoadingBalancesCsv(true);

    const toastPromise = toast.promise(exportBalancesList(this.prepareFiltersParams('csv')), {
      pending: i18n.getMessage('customersStore.handleExportBalances.toastPromise.pending'),
      success: i18n.getMessage('customersStore.handleExportBalances.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingBalancesCsv(false);
    }
  };

  getCustomerDetailsById = async (customerNumber, customerType) => {
    this.setIsLoading(true);
    try {
      let customerDetails = {};

      if (customerType === CUSTOMER_TYPE.INDIVIDUAL) {
        customerDetails = await getIndividualCustomerDetailsRequest(customerNumber);
      } else {
        customerDetails = await getCorporateCustomerDetailsRequest(customerNumber);
      }

      runInAction(() => {
        this.currentCustomerDetails = customerDetails;
        this.isCurrentCustomerDetailsReceived = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getCurrentCustomerProviders = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const providers = await getPaymentProvidersInfo(customerNumber);

      runInAction(() => {
        this.currentCustomerProviders = providers;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  addNewPaymentProvider = async (customerNumber, paymentProvider) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(
      addNewPaymentProviderRequest(customerNumber, {
        transferProvider: paymentProvider,
        userInfo: null
      }),
      {
        pending: i18n.getMessage('customersStore.addNewPaymentProvider.toastPromise.pending'),
        success: i18n.getMessage('customersStore.addNewPaymentProvider.toastPromise.success'),
        error: {
          render({ data }) {
            let errorMessage = '';

            if (data?.status === 400 && data?.message.includes('representative')) {
              errorMessage = i18n.getMessage('customersStore.addNewPaymentProvider.toastPromise.error');
            } else if (data?.code === 'ARGUMENT_NOT_VALID') {
              if (data?.fields && data?.fields.length > 0) {
                const fieldsList = data.fields.map((field) => field.field).join(', ');

                errorMessage = i18n.getMessage(
                  'customersStore.addNewPaymentProvider.toastPromise.error.ARGUMENT_NOT_VALID.fields',
                  { fields: fieldsList }
                );
              } else {
                errorMessage = i18n.getMessage(
                  'customersStore.addNewPaymentProvider.toastPromise.error.ARGUMENT_NOT_VALID'
                );
              }
            } else {
              errorMessage = `${data?.status} ${data?.message}`;
            }

            return errorMessage;
          }
        }
      }
    );

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getLinkedRuleEngineProvider = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const linkedRuleEngineProvider = await getLinkedRuleEngineProviderRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerLinkedRuleEngineProvider = linkedRuleEngineProvider;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  linkRuleEngineProvider = async (customerNumber, ruleEngine) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(linkRuleEngineProviderRequest(customerNumber, ruleEngine), {
      pending: i18n.getMessage('customersStore.linkRuleEngineProvider.toastPromise.pending'),
      success: i18n.getMessage('customersStore.linkRuleEngineProvider.toastPromise.success')
    });

    try {
      const linkedRuleEngineProvider = await toastPromise;

      runInAction(() => {
        this.currentCustomerLinkedRuleEngineProvider = linkedRuleEngineProvider;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  unlinkRuleEngineProvider = async (customerNumber) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(unlinkRuleEngineProviderRequest(customerNumber), {
      pending: i18n.getMessage('customersStore.unlinkRuleEngineProvider.toastPromise.pending'),
      success: i18n.getMessage('customersStore.unlinkRuleEngineProvider.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.currentCustomerLinkedRuleEngineProvider = null;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getCurrentCustomerCards = async (customerNumber) => {
    this.setIsCardActionsLoading(true);
    try {
      const cards = await getCustomerCardsRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerCards = cards;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  blockCustomerCard = async (customerNumber, cardId, cardBlockType) => {
    this.setIsCardActionsLoading(true);

    const toastPromise = toast.promise(blockCustomerCardRequest(customerNumber, cardId, cardBlockType), {
      pending: i18n.getMessage('customersStore.blockCustomerCard.toastPromise.pending'),
      success: i18n.getMessage('customersStore.blockCustomerCard.toastPromise.success')
    });

    try {
      const cardInfo = await toastPromise;

      runInAction(() => {
        const index = this.currentCustomerCards.findIndex((card) => card.card_id === cardId);

        if (index !== -1) {
          this.currentCustomerCards[index] = cardInfo;
        }
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  unblockCustomerCard = async (customerNumber, cardId) => {
    this.setIsCardActionsLoading(true);

    const toastPromise = toast.promise(unblockCustomerCardRequest(customerNumber, cardId), {
      pending: i18n.getMessage('customersStore.unblockCustomerCard.toastPromise.pending'),
      success: i18n.getMessage('customersStore.unblockCustomerCard.toastPromise.success')
    });

    try {
      const cardInfo = await toastPromise;

      runInAction(() => {
        const index = this.currentCustomerCards.findIndex((card) => card.card_id === cardId);

        if (index !== -1) {
          this.currentCustomerCards[index] = cardInfo;
        }
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  replaceCustomerCard = async (customerNumber, cardId) => {
    this.setIsCardActionsLoading(true);

    const toastPromise = toast.promise(replaceCustomerCardRequest(customerNumber, cardId), {
      pending: i18n.getMessage('customersStore.replaceCustomerCard.toastPromise.pending'),
      success: i18n.getMessage('customersStore.replaceCustomerCard.toastPromise.success')
    });

    try {
      const cardInfo = await toastPromise;

      runInAction(() => {
        const index = this.currentCustomerCards.findIndex((card) => card.card_id === cardId);

        if (index !== -1) {
          this.currentCustomerCards[index] = cardInfo;
        }
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  updateCardsPerCustomerLimit = async (customerNumber, cardsLimit) => {
    this.setIsCardActionsLoading(true);

    const toastPromise = toast.promise(updateCardsPerCustomerLimitRequest(customerNumber, cardsLimit), {
      pending: i18n.getMessage('customersStore.updateCardsPerCustomerLimit.toastPromise.pending'),
      success: i18n.getMessage('customersStore.updateCardsPerCustomerLimit.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails = customerDetails;
        this.isCurrentCustomerDetailsReceived = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  updateCardsPerAccountLimit = async (customerNumber, cardsLimit) => {
    this.setIsCardActionsLoading(true);

    const toastPromise = toast.promise(updateCardsPerAccountLimitRequest(customerNumber, cardsLimit), {
      pending: i18n.getMessage('customersStore.updateCardsPerAccountLimit.toastPromise.pending'),
      success: i18n.getMessage('customersStore.updateCardsPerAccountLimit.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails = customerDetails;
        this.isCurrentCustomerDetailsReceived = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  handleUpdateCustomerDetails = async (customerNumber, data, customerType) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(
      customerType === CUSTOMER_TYPE.INDIVIDUAL
        ? updateIndividualCustomerDetailsRequest(customerNumber, data)
        : updateCorporateCustomerDetailsRequest(customerNumber, data),
      {
        pending: i18n.getMessage('customersStore.handleUpdateCustomerDetails.toastPromise.pending'),
        success: i18n.getMessage('customersStore.handleUpdateCustomerDetails.toastPromise.success'),
        error: {
          render({ data }) {
            let errorMessage = '';

            if (data.code === 'WRONG_USER_STATUS') {
              errorMessage = i18n.getMessage(
                `customersStore.handleUpdateCustomerDetails.toastPromise.error.${data?.code}`
              );
            } else {
              errorMessage = `${data?.status} ${data?.message}`;
            }

            return errorMessage;
          }
        }
      }
    );

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails = { ...this.currentCustomerDetails, ...customerDetails };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeCustomerEmail = async (customerNumber, email) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateCustomerEmailRequest(customerNumber, email), {
      pending: i18n.getMessage('customersStore.handleChangeCustomerEmail.toastPromise.pending'),
      success: i18n.getMessage('customersStore.handleChangeCustomerEmail.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.email = customerDetails.email;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeCustomerStatus = async (customerNumber, customerStatus) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateCustomerStatusRequest(customerNumber, customerStatus), {
      pending: i18n.getMessage('customersStore.handleChangeCustomerStatus.toastPromise.pending'),
      success: i18n.getMessage('customersStore.handleChangeCustomerStatus.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.status = customerDetails.status;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeOnboardingStatus = async (customerNumber, onboardingStatus) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateOnboardingStatusRequest(customerNumber, onboardingStatus), {
      pending: i18n.getMessage('customersStore.handleChangeOnboardingStatus.toastPromise.pending'),
      success: i18n.getMessage('customersStore.handleChangeOnboardingStatus.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.onboardingStatus = customerDetails.onboardingStatus;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeKycStatus = async (customerNumber, kycStatus) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateKycStatusRequest(customerNumber, kycStatus), {
      pending: i18n.getMessage('customersStore.handleChangeKycStatus.toastPromise.pending'),
      success: i18n.getMessage('customersStore.handleChangeKycStatus.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.kyc_status = customerDetails.kyc_status;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeExternalKycReference = async (customerNumber, externalKycReference) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(
      updateCustomerExternalKycReferenceRequest(customerNumber, externalKycReference),
      {
        pending: i18n.getMessage('customersStore.handleChangeExternalKycReference.toastPromise.pending'),
        success: i18n.getMessage('customersStore.handleChangeExternalKycReference.toastPromise.success')
      }
    );

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.kycExternalReference = customerDetails.kycExternalReference;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeRiskProfile = async (customerNumber, status) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateRiskProfileRequest(customerNumber, status), {
      pending: i18n.getMessage('customersStore.handleChangeRiskProfile.toastPromise.pending'),
      success: i18n.getMessage('customersStore.handleChangeRiskProfile.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.risk_profile = customerDetails.risk_profile;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeMlroApproval = async (customerNumber, status) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateMlroApprovalRequest(customerNumber, status), {
      pending: i18n.getMessage('customersStore.handleChangeMlroApproval.toastPromise.pending'),
      success: i18n.getMessage('customersStore.handleChangeMlroApproval.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.mlro_approval = customerDetails.mlro_approval;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeDirectorApproval = async (customerNumber, status) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateDirectorApprovalRequest(customerNumber, status), {
      pending: i18n.getMessage('customersStore.handleChangeDirectorApproval.toastPromise.pending'),
      success: i18n.getMessage('customersStore.handleChangeDirectorApproval.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.director_approval = customerDetails.director_approval;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeNextAMLCheckDate = async (customerNumber, status) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateNextAMLCheckDateRequest(customerNumber, status), {
      pending: i18n.getMessage('customersStore.handleChangeNextAMLCheckDate.toastPromise.pending'),
      success: i18n.getMessage('customersStore.handleChangeNextAMLCheckDate.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.next_aml_check_date = customerDetails.next_aml_check_date;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleReceiveKycData = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      await requestExternalKyc(customerNumber);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getCompanyStructure = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const { authorized_person, beneficiaries, directors } = await getCompanyStructureRequest(customerNumber);

      runInAction(() => {
        this.companyStructure.authorizedPerson = authorized_person;
        this.companyStructure.beneficiaries = beneficiaries;
        this.companyStructure.directors = directors;
        this.isCompanyStructureLoaded = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  sendCompanyStructure = async (customerNumber, companyStructure) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(sendCompanyStructureRequest(customerNumber, companyStructure), {
      pending: i18n.getMessage('customersStore.sendCompanyStructure.toastPromise.pending'),
      success: i18n.getMessage('customersStore.sendCompanyStructure.toastPromise.success')
    });

    try {
      const { authorized_person, beneficiaries, directors } = await toastPromise;

      runInAction(() => {
        this.companyStructure.authorizedPerson = authorized_person;
        this.companyStructure.beneficiaries = beneficiaries;
        this.companyStructure.directors = directors;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  deleteCompanyStructureMember = async (customerNumber, memberCustomerNumber) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteCompanyStructureMemberRequest(customerNumber, memberCustomerNumber), {
      pending: i18n.getMessage('customersStore.deleteCompanyStructureMember.toastPromise.pending'),
      success: i18n.getMessage('customersStore.deleteCompanyStructureMember.toastPromise.success')
    });

    try {
      const { authorized_person, beneficiaries, directors } = await toastPromise;

      runInAction(() => {
        this.companyStructure.authorizedPerson = authorized_person;
        this.companyStructure.beneficiaries = beneficiaries;
        this.companyStructure.directors = directors;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getLimitsList = async () => {
    this.setIsLoading(true);
    try {
      const limitsList = await getLimitListRequest();

      runInAction(() => {
        this.limits = limitsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getTariffGroupsList = async () => {
    this.setIsLoading(true);
    try {
      const tariffsList = await getTariffGroupsListRequest();

      runInAction(() => {
        this.tariffs = tariffsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getTariffsGroup = async (groupId) => {
    this.setIsLoading(true);
    try {
      const tariffsGroup = await getTariffsGroupRequest(groupId);

      runInAction(() => {
        this.selectedTariffsGroup = tariffsGroup;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  changeCustomerTariffGroup = async (customerNumber, groupId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(changeCustomerTariffGroupRequest(customerNumber, groupId), {
      pending: i18n.getMessage('customersStore.changeCustomerTariffGroup.toastPromise.pending'),
      success: i18n.getMessage('customersStore.changeCustomerTariffGroup.toastPromise.success')
    });

    try {
      const tariffsGroup = await toastPromise;

      runInAction(() => {
        this.selectedTariffsGroup = tariffsGroup;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  applyTariff = async (customerNumber, tariffType) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(applyTariffRequest(customerNumber, tariffType), {
      pending: i18n.getMessage('customersStore.applyTariff.toastPromise.pending'),
      success: i18n.getMessage('customersStore.applyTariff.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  addRepresentative = async (customerNumber, email, permissions) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(addRepresentativeRequest(customerNumber, email, permissions), {
      pending: i18n.getMessage('customersStore.addRepresentative.toastPromise.pending'),
      success: i18n.getMessage('customersStore.addRepresentative.toastPromise.success')
    });

    try {
      const newRepresentative = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.representatives = [
          ...this.currentCustomerDetails.representatives,
          newRepresentative
        ];
        this.representativePopUpType = REPRESENTATIVE_POP_UP.SUCCESS;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        if (err?.code === 'ACCOUNT_NOT_FOUND') {
          this.representativePopUpType = REPRESENTATIVE_POP_UP.ERROR_USER_NOT_APPLICATION;
        } else if (err?.code === 'WRONG_ACCOUNT_STATUS') {
          this.representativePopUpType = REPRESENTATIVE_POP_UP.ERROR_NOT_VERIFIED;
        } else if (err?.code === 'REPRESENTATIVE_ALREADY_ADDED') {
          this.representativePopUpType = REPRESENTATIVE_POP_UP.ERROR_ALREADY_ADDED;
        } else if (err?.code === 'ADDING_YOURSELF_AS_REPRESENTATIVE') {
          this.representativePopUpType = REPRESENTATIVE_POP_UP.ERROR_ADDING_YOURSELF_AS_REPRESENTATIVE;
        } else if (err?.code === 'ARGUMENT_NOT_VALID') {
          this.representativePopUpType = REPRESENTATIVE_POP_UP.ERROR_INVALID_EMAIL;
        } else {
          this.representativePopUpType = REPRESENTATIVE_POP_UP.ERROR;
        }
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  removeRepresentative = async (customerNumber, representativeId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(removeRepresentativeRequest(customerNumber, representativeId), {
      pending: i18n.getMessage('customersStore.removeRepresentative.toastPromise.pending'),
      success: i18n.getMessage('customersStore.removeRepresentative.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.representatives = this.currentCustomerDetails.representatives.filter(
          (representative) => representative.id !== representativeId
        );
        this.representativePopUpType = REPRESENTATIVE_POP_UP.SUCCESS;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  updateRepresentativePermissions = async (customerNumber, representativeId, permissions) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateRepresentativeRequest(customerNumber, representativeId, permissions), {
      pending: i18n.getMessage('customersStore.updateRepresentativePermissions.toastPromise.pending'),
      success: i18n.getMessage('customersStore.updateRepresentativePermissions.toastPromise.success')
    });

    try {
      const representativeDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.representatives = this.currentCustomerDetails.representatives.map((rep) =>
          rep.id === representativeDetails.id ? representativeDetails : rep
        );
        this.representativePopUpType = REPRESENTATIVE_POP_UP.SUCCESS;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        if (err?.code === 'ACCOUNT_NOT_FOUND') {
          this.representativePopUpType = REPRESENTATIVE_POP_UP.ERROR_USER_NOT_APPLICATION;
        } else if (err?.code === 'WRONG_ACCOUNT_STATUS') {
          this.representativePopUpType = REPRESENTATIVE_POP_UP.ERROR_NOT_VERIFIED;
        } else {
          this.representativePopUpType = REPRESENTATIVE_POP_UP.ERROR;
        }
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  setDeleteAvailability = (note) => {
    const deleteAvailable = note.deleteExpiresIn > 0;
    note.deleteAvailable = deleteAvailable;

    if (deleteAvailable) {
      setTimeout(() => {
        runInAction(() => {
          this.currentCustomerNotes = this.currentCustomerNotes.map((el) =>
            el.id === note.id ? { ...el, deleteAvailable: false } : el
          );
        });
      }, note.deleteExpiresIn * 1000);
    }

    return note;
  };

  loadCustomerNotes = async (customerNumber) => {
    this.setIsLoadingNotes(true);
    try {
      const customerNotes = await getCustomerNotesRequest(customerNumber);

      const checkedNotes = customerNotes.map(this.setDeleteAvailability);

      runInAction(() => {
        this.currentCustomerNotes = checkedNotes;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  loadCustomerAmlNotes = async (customerNumber) => {
    this.setIsLoadingNotes(true);
    try {
      const customerAmlNotes = await getCustomerAmlNotesRequest(customerNumber);

      const checkedAmlNotes = customerAmlNotes.map(this.setDeleteAvailability);

      runInAction(() => {
        this.currentCustomerAmlNotes = checkedAmlNotes;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  loadCustomerTransactionNotes = async (customerNumber) => {
    this.setIsLoadingNotes(true);
    try {
      const res = await getCustomerTransactionsNotesRequest(customerNumber, {
        page: this.pagination.page,
        size: this.pagination.size,
        totalPages: this.pagination.totalPages,
        totalElements: this.totalElements
      });

      runInAction(() => {
        this.currentCustomerTransactionsNotes = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  addNote = async (customerNumber, transactionNumber, note, assigneeId, documents) => {
    this.setIsLoadingNotes(true);

    const toastPromise = toast.promise(
      addCustomerNoteRequest(customerNumber, transactionNumber, note, assigneeId, documents),
      {
        pending: i18n.getMessage('customersStore.addNote.toastPromise.pending'),
        success: i18n.getMessage('customersStore.addNote.toastPromise.success')
      }
    );

    try {
      const newNote = await toastPromise;

      const checkedNote = this.setDeleteAvailability(newNote);

      runInAction(() => {
        this.currentCustomerNotes = [checkedNote, ...this.currentCustomerNotes];
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  addAmlNote = async (customerNumber, note, assigneeId, documents) => {
    this.setIsLoadingNotes(true);

    const toastPromise = toast.promise(addAmlNoteRequest(customerNumber, note, assigneeId, documents), {
      pending: i18n.getMessage('customersStore.addAmlNote.toastPromise.pending'),
      success: i18n.getMessage('customersStore.addAmlNote.toastPromise.success')
    });

    try {
      const newAmlNote = await toastPromise;

      const checkedAmlNote = this.setDeleteAvailability(newAmlNote);

      runInAction(() => {
        this.currentCustomerAmlNotes = [checkedAmlNote, ...this.currentCustomerAmlNotes];
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  deleteNote = async (noteId) => {
    this.setIsLoadingNotes(true);

    const toastPromise = toast.promise(deleteNoteRequest(noteId), {
      pending: i18n.getMessage('customersStore.deleteNote.toastPromise.pending'),
      success: i18n.getMessage('customersStore.deleteNote.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.currentCustomerNotes = this.currentCustomerNotes.filter((note) => note.id !== noteId);
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  deleteAmlNote = async (noteId) => {
    this.setIsLoadingNotes(true);

    const toastPromise = toast.promise(deleteNoteRequest(noteId), {
      pending: i18n.getMessage('customersStore.deleteAmlNote.toastPromise.pending'),
      success: i18n.getMessage('customersStore.deleteAmlNote.toastPromise.success')
    });

    try {
      await toastPromise;

      runInAction(() => {
        this.currentCustomerAmlNotes = this.currentCustomerAmlNotes.filter((note) => note.id !== noteId);
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  downloadNoteDocument = async (documentId) => {
    this.setIsLoadingNotes(true);

    const toastPromise = toast.promise(downloadNoteDocumentRequest(documentId), {
      pending: i18n.getMessage('customersStore.downloadNoteDocument.toastPromise.pending'),
      success: i18n.getMessage('customersStore.downloadNoteDocument.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  loadCustomerLogs = async (customerNumber) => {
    this.setIsLoadingLogs(true);
    try {
      const res = await getCustomerLogsRequest(customerNumber, {
        page: this.pagination.page,
        size: this.pagination.size,
        totalPages: this.pagination.totalPages,
        totalElements: this.totalElements
      });

      runInAction(() => {
        this.currentCustomerLogs = res.logs.content;
        this.firstAccountCreatedAt = res.first_wallet_creation_date;
        this.totalElements = res.logs.total_elements;
        this.pagination = {
          size: res.logs.size,
          page: res.logs.number,
          totalPages: res.logs.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingLogs(false);
    }
  };

  loadCustomerMonitoringDocuments = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const documents = await getPaymentDocumentsRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerMonitoringDocuments = documents;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  downloadCustomerMonitoringDocumentById = async (documentId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(downloadPaymentTransactionDocumentRequest(documentId), {
      pending: i18n.getMessage('customersStore.downloadCustomerMonitoringDocumentById.toastPromise.pending'),
      success: i18n.getMessage('customersStore.downloadCustomerMonitoringDocumentById.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getAmlTemplate = async (customerType) => {
    this.setIsLoading(true);
    try {
      const amlTemplate = await getAmlTemplateRequest(customerType);

      runInAction(() => {
        this.currentCustomerAml = amlTemplate;
        this.error = null;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getCustomerAml = async (customerNumber, customerType) => {
    this.setIsLoading(true);
    try {
      const customerAml = await getAmlRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerAml = customerAml;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });

      if (err.code === 'BAD_REQUEST' && err.message === 'Aml not found.') {
        await this.getAmlTemplate(customerType);
      }
    } finally {
      this.setIsLoading(false);
    }
  };

  saveAmlForm = async (customerNumber, aml) => {
    this.setIsLoading(true);
    try {
      const customerAml = await saveAmlRequest(customerNumber, aml);

      runInAction(() => {
        this.currentCustomerAml = customerAml;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  loadCustomerPdfReport = async (customerNumber, from, to) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(loadCustomerPdfReportRequest(customerNumber, from, to), {
      pending: i18n.getMessage('customersStore.loadCustomerPdfReport.toastPromise.pending'),
      success: i18n.getMessage('customersStore.loadCustomerPdfReport.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  loadCustomerCsvReport = async (customerNumber, from, to) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(loadCustomerCsvReportRequest(customerNumber, from, to), {
      pending: i18n.getMessage('customersStore.loadCustomerCsvReport.toastPromise.pending'),
      success: i18n.getMessage('customersStore.loadCustomerCsvReport.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getCustomerDocuments = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const customerDocuments = await getCustomerDocumentsRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerDocuments = customerDocuments;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  uploadCustomerDocument = async (customerNumber, data) => {
    this.setIsLoading(true);

    try {
      const newDocument = await uploadCustomerDocumentRequest(customerNumber, data);

      runInAction(() => {
        this.currentCustomerDocuments = [...this.currentCustomerDocuments, newDocument];
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  updateCustomerDocument = async (customerNumber, documentId, data) => {
    this.setIsLoading(true);
    try {
      const updatedDocument = await updateCustomerDocumentRequest(customerNumber, documentId, data);

      runInAction(() => {
        this.currentCustomerDocuments = this.currentCustomerDocuments.map((document) =>
          document.documentUid === updatedDocument.documentUid ? updatedDocument : document
        );
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  deleteCustomerDocument = async (customerNumber, documentId) => {
    this.setIsLoading(true);
    try {
      await deleteCustomerDocumentRequest(customerNumber, documentId);

      runInAction(() => {
        this.currentCustomerDocuments = this.currentCustomerDocuments.filter(
          (document) => document.documentUid !== documentId
        );
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  downloadCustomerDocumentById = async (customerNumber, documentId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(downloadDocumentByDocumentIdRequest(customerNumber, documentId), {
      pending: i18n.getMessage('customersStore.downloadCustomerDocumentById.toastPromise.pending'),
      success: i18n.getMessage('customersStore.downloadCustomerDocumentById.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  activateUser = async (userId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(activateCustomerRequest(userId), {
      pending: i18n.getMessage('customersStore.activateUser.toastPromise.pending'),
      success: i18n.getMessage('customersStore.activateUser.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;
      runInAction(() => {
        this.currentCustomerDetails.user = customerDetails?.user;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  blockUser = async (userId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(blockCustomerRequest(userId), {
      pending: i18n.getMessage('customersStore.blockUser.toastPromise.pending'),
      success: i18n.getMessage('customersStore.blockUser.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;
      runInAction(() => {
        this.currentCustomerDetails.user = customerDetails?.user;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  administrateUser = async (userId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(administrateCustomerRequest(userId), {
      pending: i18n.getMessage('customersStore.administrateUser.toastPromise.pending'),
      success: i18n.getMessage('customersStore.administrateUser.toastPromise.success')
    });

    try {
      const customerDetails = await toastPromise;

      runInAction(() => {
        this.currentCustomerDetails.user = customerDetails?.user;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default new CustomersStore();
