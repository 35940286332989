import { useContext } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import i18nContext from 'components/i18n-context';
import { CustomTimePicker } from 'uikit/CustomTimePicker';
import 'react-multi-date-picker/styles/colors/purple.css';
import './PaymentsCutoffTimesTableRow.scss';

const PaymentsCutoffTimesTableRow = ({ paymentWorkTimeData, handleTimeChange, handleNonWorkingDaysChange }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      <td className={'payments-cutoff-times-table-cell'}>
        <p>{i18n.getMessage(`paymentMethod.${paymentWorkTimeData?.payment_method}`)}</p>
      </td>
      <td className={'payments-cutoff-times-table-cell'}>
        <CustomTimePicker
          className={'payments-cutoff-times-time-input'}
          autoComplete={'false'}
          name={'from'}
          value={paymentWorkTimeData?.from}
          onChange={(name, value) => handleTimeChange(name, value, paymentWorkTimeData)}
          isShowTimeSelect={true}
          isShowTimeSelectOnly={true}
          timeIntervals={1}
        />
      </td>
      <td className={'payments-cutoff-times-table-cell'}>
        <CustomTimePicker
          className={'payments-cutoff-times-time-input'}
          autoComplete={'false'}
          name={'to'}
          value={paymentWorkTimeData?.to}
          onChange={(name, value) => handleTimeChange(name, value, paymentWorkTimeData)}
          isShowTimeSelect={true}
          isShowTimeSelectOnly={true}
          timeIntervals={1}
        />
      </td>
      <td className={'payments-cutoff-times-table-cell'}>
        <DatePicker
          multiple
          value={paymentWorkTimeData?.non_working_days}
          className={'purple'}
          onChange={(newNonWorkingDays) => handleNonWorkingDaysChange(newNonWorkingDays, paymentWorkTimeData)}
          style={{
            height: '40px',
            width: '100%',
            borderColor: '#d9d9d9',
            color: '#495057'
          }}
          containerStyle={{
            width: '100%'
          }}
          weekStartDayIndex={1}
          //eslint-disable-next-line react/jsx-key
          plugins={[<DatePanel />]}
          currentDate={new DateObject()}
        />
      </td>
    </tr>
  );
};

PaymentsCutoffTimesTableRow.propTypes = {
  paymentWorkTimeData: PropTypes.shape({
    payment_method: PropTypes.string,
    from: PropTypes.object,
    to: PropTypes.object,
    non_working_days: PropTypes.array,
    type: PropTypes.string
  }),
  handleTimeChange: PropTypes.func,
  handleNonWorkingDaysChange: PropTypes.func
};

export default PaymentsCutoffTimesTableRow;
