import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import i18nContext from 'components/i18n-context';
import { MAX_MESSAGE_LENGTH, MAX_UPLOADED_FILES_SIZE, MESSAGE_REG_EXP } from 'components/constants';
import Loader from 'components/Loader';
import { convertBytesToMegabytes, convertHtmlToString, convertMessageToHtmlAndTrimSpaces } from 'services/utils';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import './TopicMessageForm.scss';

const TopicMessageForm = ({
  isLoading,
  isFileUploading,
  isNewMessageCreated,
  setIsNewMessageCreated,
  topicId,
  sendTopicMessage,
  uploadedFiles,
  uploadAttachments,
  removeAttachment
}) => {
  const i18n = useContext(i18nContext);
  const [messageValue, setMessageValue] = useState(RichTextEditor.createEmptyValue());

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      message: ''
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required(i18n.getMessage('messages.error.message.empty'))
        .max(MAX_MESSAGE_LENGTH, i18n.getMessage('messages.error.message.maxSize', { size: MAX_MESSAGE_LENGTH }))
        .matches(MESSAGE_REG_EXP, i18n.getMessage('messages.error.message.invalidFormat'))
    }),
    onSubmit: async () => {
      await sendTopicMessage(topicId, {
        messageBody: convertMessageToHtmlAndTrimSpaces(messageValue),
        documents: uploadedFiles.filter(({ id }) => id).map(({ id }) => id)
      });
    }
  });

  const { values, errors, handleSubmit, validateField, setFieldValue, submitCount } = form;

  const handleChangeMessageField = (value) => {
    setMessageValue(value);
    setFieldValue('message', convertHtmlToString(value));
  };

  const handleBlurMessageField = () => {
    validateField('message');
  };

  useEffect(() => {
    if (isNewMessageCreated) {
      setMessageValue(RichTextEditor.createEmptyValue());
      setFieldValue('message', '');
      setIsNewMessageCreated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewMessageCreated]);

  return (
    <form action={'#'} id={'send'} onSubmit={handleSubmit} className={'topic-message-form'}>
      <Input
        className={'topic-message-form-message-input'}
        type={'text-editor'}
        label={i18n.getMessage('messages.topicMessages.field.message.label')}
        name={'message'}
        value={messageValue}
        onChange={handleChangeMessageField}
        onBlur={handleBlurMessageField}
        initialStatus={submitCount}
        max={MAX_MESSAGE_LENGTH}
        rows={4}
        error={errors?.message}
        /* eslint-disable-next-line max-len */
        subText={i18n.getMessage('messages.topicMessages.field.message.symbolsLeft', {
          amount: MAX_MESSAGE_LENGTH - values.message.length
        })}
      />
      <AttachDoc
        label={i18n.getMessage('messages.topicMessages.field.attachDoc.label', {
          amount: uploadedFiles.length
        })}
        className={'topic-message-form-attachments'}
        type={'file'}
        size={MAX_UPLOADED_FILES_SIZE}
        files={uploadedFiles}
        onChange={uploadAttachments}
        onRemoveFiles={removeAttachment}
        isDisabled={isLoading || isFileUploading}
        // error={error?.type === 'attachDoc' && getErrorMessageForAlert(i18n, error)}
        hintText={i18n.getMessage('attachDoc.hint.text', {
          maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
        })}
      />
      <Button
        className={'topic-message-form-button'}
        type={'primary'}
        roleType={'submit'}
        size={'large'}
        fullWidth={true}
        onClick={() => {}}
        isDisabled={isLoading || isFileUploading || !!errors?.message}
      >
        {isLoading ? <Loader /> : i18n.getMessage('messages.topicMessages.button.sendMessage')}
      </Button>
    </form>
  );
};

TopicMessageForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFileUploading: PropTypes.bool.isRequired,
  isNewMessageCreated: PropTypes.bool.isRequired,
  setIsNewMessageCreated: PropTypes.bool.isRequired,
  topicId: PropTypes.string.isRequired,
  sendTopicMessage: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  uploadAttachments: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired
};

export default TopicMessageForm;
